import { createApp } from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import '@popperjs/core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab, faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import axios from "axios";
import { global } from "./global";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

axios.defaults.baseURL = global.url;

library.add(fab, fas, faFacebook, faInstagram, faTwitter)

const app = createApp(App);
app.use(router)
app.use(Vuetify)
app.use(VueSweetalert2)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
