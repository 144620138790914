
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "./views/web/HomePage";


const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: "/",
        name: "home-page",
        component: HomePage
      },
      {
        path: "/vouchers",
        name: "promo-page",
        component: () => import('./views/web/PromoPage.vue')
      },
      {
        path: "/privacy-policy",
        name: "privacy-page",
        component: () => import('./views/web/PrivacyPolicy.vue')
      },
      {
        path: "/terms-conditions",
        name: "terms-conditions-page",
        component: () => import('./views/web/TermConditions.vue')
      },
      {
        path: "/faqs",
        name: "faq-page",
        component: () => import('./views/web/Faqs.vue')
      },
      {
        path: "/contact",
        name: "contact-page",
        component: () => import('./views/web/ContactPage.vue')
      },
      {
        path: "/voucher-code",
        name: "promo-code-page",
        component: () => import('./views/web/PromoCode.vue')
      },
      {
        path: "/success",
        name: "success-page",
        component: () => import('./views/web/SuccessPage.vue')
      },
      {
        path: '/:catchAll(.*)',
        component: () => import('./views/web/NotFound.vue')
      }
    ]
});

export default router;