<template>
  <div class="d-flex flex-column" style="min-height: 100vh">
    <Header v-if="$route.name != 'home-page' && $route.name != 'promo-page' && $route.name != 'promo-code-page' && $route.name != 'success-page'" />
    <div class="container-fluid flex-grow-1">
      <div class="row">
        <div class="col-1" v-if="$route.name != 'promo-page' && $route.name != 'promo-code-page' && $route.name != 'success-page'" style="min-height: 100%;">
          <SideNav v-if="$route.name != 'home-page'" class="h-100" />
        </div>
        <div :class="$route.name === 'promo-page' || $route.name === 'promo-code-page' || $route.name === 'success-page' ? 'col-12' : 'col-11'">
          <router-view />
        </div>
      </div>
    </div>
    <FooterComponent v-if="$route.name != 'home-page'" />
  </div>
</template>

<script>
import Header from "./components/TopHeader.vue";
import SideNav from "./components/SideNav.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    Header,
    SideNav,
    FooterComponent,
  },
};
</script>

<style>
@import "@/assets/css/main.css";

.footer {
  margin-top: auto;
}
</style>