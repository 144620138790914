<template>
    <div class="social-media-buttons">
      <a href="https://www.facebook.com/mcdonaldsbrunei/" target="_blank">
        <font-awesome-icon :icon="['fab', 'facebook']" />
      </a>
      <a href="https://www.instagram.com/mcdonaldsbrunei/?hl=en" target="_blank">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a href="https://twitter.com/mcdbrunei" target="_blank">
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </a>
    </div> 
  </template>
  
  <script>
  export default {
    name: "SocialMediaButtons",
  };
  </script>
  
  <style scoped>
  .social-media-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
  
  .social-media-buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    font-size: 30px;
    color: white;
    width: 40px;
    height: 40px;
    transition: all 0.3s ease-in-out;
  }
  
  .social-media-buttons a:hover {
    transform: scale(1.2);
  }
  
  @media only screen and (min-width: 768px) {
    .social-media-buttons {
      bottom: 3%;
    }
  
    .social-media-buttons a {
      font-size: 40px;
      width: 50px;
      height: 50px;
    }
  }
  </style>