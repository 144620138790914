export const global = {
  base_url: "https://mcdonalds.com.bn",

  get url() {
    return this.base_url + "/api";
  },

  get app_version() {
    return "1.0.0";
  },
};
