<template>
  <nav class="navbar navbar-expand-lg navbar-white bg-white">
    <div class="container-fluid">
      <!-- <router-link class="navbar-brand" to="/">
        <img src="@/assets/images/McD_TheToken_1235_RGB.svg" alt="Logo" />
      </router-link> -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
        <ul class="navbar-nav">
          <li class="nav-item">
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TopHeader",
};
</script>

<style scoped>
nav {
  background-color: transparent !important;
  box-shadow: none !important;
}
</style>