<template>
  <div class=".landing-page">
      <!-- <router-link to="/terms-conditions">
        <img src="@/assets/images/McD_TheToken_1235_RGB.svg" alt="coming-soon" class="logo img-fluid">
      </router-link> -->
  
    <div class="coming-soon">
      <h1 class="card-text-home speedee-bold font-white ">{{ pageContent }}</h1>
    </div>
    <div class="background-image"></div>

    <SocialMediaButtons />
  </div>
</template>
  
<script>
import SocialMediaButtons from './SocialMediaButtons.vue';
import titleMixin from '@/mixins/TitleMixins.js';

export default {
  name: "home-page",
  mixins: [ titleMixin ],
  components: {
    SocialMediaButtons
  },

  data() {
    return {
      pageTitle: '',
      pageContent: 'Coming Soon'
    }
  }
};
</script>



<style scoped>
.landing-page {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('@/assets/images/mcdonald-second-no-text.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.logo {
  position: absolute;
  top: 30px;
  left: 50px;
  width: 80px;
  z-index: 2;
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.card-text-home {
  font-size: 6.5vw;
  color: white;
}

.coming-soon {
  display: grid;
  place-items: center;
  justify-items: start;
  height: 100vh;
}

</style>
  